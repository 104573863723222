<template>
  <div>

    <HeaderMain />

    <!-- ТЕЛЕФОННЫЕ МОШЕННИКИ -->
    <div class="section color">

      <div
          data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container one w-container pb-0 pt-0" style="border-bottom: none;">
        <div class="w-layout-grid grid-2-colum" style="grid-column-gap: unset;">
          <!-- блок 1 -->
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum left w-100 gray-container"
               style="
               padding: 25px;
               padding-bottom: 35px;
               border-radius: unset;
               position: relative;
               overflow: hidden;
               min-height: 500px;
               max-height: 500px;
            ">
            <div class="w-layout-vflex tittle"
                 style="
                 z-index: 1;
                 justify-content: space-between;"
            >
              <div class="w-layout-vflex heading-title">
                <h1 class="heading">Напишите<br>нам</h1>
              </div>
              <div class="w-layout-vflex flex-good"
                   style="flex-flow: row;"
              >
                <h1 class="text-mini white">Мы внимательно<br>изучаем все обращения</h1>
              </div>
            </div>

            <!-- Картинка в углу -->
            <img src="../../../../../public/images/write_us.png" alt="write us"
                 style="position: absolute;
                 bottom: 0;
                 max-height: 75%;
                 right: 0;"
            >
          </div>
          <!-- блок 2 -->
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum right w-100"
               style="
               padding: 25px;
               padding-bottom: 35px;
            ">
            <div class="w-layout-vflex item _1 w-100">
              <div class="text-mini semibold">Форма обращения</div>

              <div class="w-layout-hflex item-row gap-1" style="margin-top: 20px;">
                <div class="text-classic">
                  <img class="img-20" src="../../../../../public/images/exclamation_mark.svg" alt="exclamation">
                </div>
                <div class="w-layout-vflex item-col center gap-2">
                  <h2 class="heading h4 accent-color">Сразу после зачисления средств вам будет доступен новый заём. Как правило, Броктон увеличивает размер лимита для добросовестных клиентов!</h2>
                </div>
              </div>

              <div class="w-layout-vflex flex-input gap-10">


                <form
                    style="padding: 0; max-width: unset;"
                    id="wf-form-Login"
                    name="wf-form-Login"
                    data-name="Login"
                    method="get"
                    class="form-input-login"
                    data-wf-page-id="66628b11ce9ac8f5a081c327"
                    data-wf-element-id="80cb5f26-991f-0842-cdc6-d86456db53e0">
                  <v-form
                      ref="form_reg1" v-model="valid_form_write" lazy-validation>
                    <div class="input-box">
                      <label class="input-label">Тема обращения</label>
                      <select
                          v-model="items_form_selected"
                          class="input-form w-select"
                      >
                        <option v-for="item in items_form" :key="item.id" :value="item.id">
                          {{ item.name }}
                        </option>
                      </select>
                    </div>

                    <div class="input-box">
                      <label class="input-label">Текст письма</label>
                      <v-textarea
                          :rules="notEmptyRules"
                          v-model="textOfMessage"
                          outlined>

                      </v-textarea>
                    </div>

                    <div class="input-box">
                      <a
                          @click="triggerFileInput"
                          class="button-bust w-button gray-button w-100">
                        <v-icon left class="mr-2">mdi-paperclip</v-icon>
                        Прикрепить файл
                      </a>
                      <input
                          ref="fileInput"
                          type="file"
                          multiple
                          class="d-none"
                          @change="handleFileChange"
                      />
                    </div>

                    <div class="w-100" style="margin-top: 30px; margin-bottom: 30px;">
                      <p class="text-mini semibold align-left small">Максимальный размер файла 10 Mb.</p>
                      <p class="text-mini semibold align-left small">Максимальное количество файлов — до 20 шт.</p>
                      <p class="text-mini semibold align-left small">Общий размер всех загруженных файлов — до 50 Mb.</p>
                      <p class="text-mini semibold align-left small">Допустимые форматы: jpg/jpeg, png, bmp, pdf.</p>
                    </div>

                    <div class="input-box">
                      <label class="input-label">Фамилия Имя Отчество</label>
                      <v-text-field
                          outlined
                          :rules="notEmptyRules"
                          v-model="full_name"
                          placehlolder="Фамилия Имя Отчество"
                      ></v-text-field>
                    </div>

                    <div class="input-box">
                      <label class="input-label">Телефон</label>
                      <v-text-field
                          outlined
                          inputmode="numeric"
                          :rules="phoneRules"
                          v-mask="'+7##########'"
                          v-model="phone"
                      ></v-text-field>
                    </div>

                    <div class="input-box">
                      <label class="input-label">Email</label>
                      <v-text-field
                          id="Email"
                          name="Email"
                          outlined
                          inputmode="email"
                          type="email"
                          :rules="emailRules"
                          v-model="email"
                      ></v-text-field>
                    </div>

                    <label class="w-checkbox checkbox-wrap-next" style="margin-top: 10px; margin-bottom: 30px;">
                      <div
                          class="w-checkbox-input w-checkbox-input--inputType-custom ms-checkbox-2"
                          :class="{ 'w--redirected-checked': agreement }"
                          @click.prevent="agreement = !agreement">
                      </div>
                      <input
                          v-model="agreement"
                          type="checkbox"
                          name="Soglachenie"
                          id="Soglachenie"
                          data-name="Soglachenie"
                          style="opacity:0;position:absolute;z-index:-1">
                      <span
                          @click.prevent="agreement = !agreement"
                          class="text-classic top w-form-label"
                          for="Soglachenie">Я ознакомлен с <a @click="goToDocument('privacy-policy')" class="link-form">Политикой в отношении обработки персональных данных</a> и даю <a @click="goToDocument('processing-personal-data')" class="link-form">Согласие</a> на обработку персональных данных</span>
                    </label>

                    <a @click="sendForm" class="button-bust w-button blue-button w-100">Отправить</a>
                  </v-form>
                </form>

              </div>


            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Броктон В ТВОЁМ ТЕЛЕФОНЕ (2) -->
    <div class="section color">
      <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container one w-container pt-0" style="border-bottom: none;">
        <div class="w-layout-grid grid-1-colum" style="grid-column-gap: unset;">
          <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum left w-100"
               style="
               position: relative; overflow: hidden;
               padding: 25px 30px 35px 25px;
               border-radius: unset;
               background: linear-gradient(135deg, #005BFA 0%, #005BFA 32%, #CFDCF0 90%);
               background-position: 50% 0;
               background-size: 150% 150%;">
            <div class="w-layout-vflex tittle"
                 style="justify-content: space-between;"
            >
              <div class="w-layout-vflex heading-title w-100" style="z-index: 1;">
                <div class="flex w-100 space-between" style="gap: 10px;">
                  <h1 class="heading" style="color: #fff;">Броктон в твоём<br>телефоне</h1>
                  <img alt="install" src="../../../../../public/images/google_play.svg" class="install-image">
                </div>
              </div>

              <!-- Картинка в углу -->
              <img src="../../../../../public/images/money.png" alt="money"
                   class="money-width-2"
              >

              <!-- блок разных вариантов установки, появляется на мобилках -->
              <div class="w-layout-vflex flex-good hidden-install"
                   style="
                       align-items: end;
                       flex-flow: row;
                       z-index: 2;
                       justify-content: space-around;"
              >
                <div>
                  <img height="60px" alt="googleplay" src="../../../../../public/images/google_play.svg">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />

  </div>
</template>

<script>
import { Footer, HeaderMain } from '/src/app/shared/components';
import loadjs from 'loadjs';

export default {
  name: 'WriteToUs',
  data() {
    return {
      phoneRules: [
        v => !!v || 'Поле не может быть пустым',
        v => /^(8\d{10}|\+7\d{10})$/.test(v) || 'Телефон должен состоять из 11 цифр и начинаться с +7'
      ],
      emailRules: [
        v => !!v || 'Поле не может быть пустым',
        v => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || 'Неправильный формат email'
      ],
      notEmptyRules: [
        v => !!v || 'Поле не может быть пустым',
      ],

      valid_form_write: false,
      phone: '',
      email: '',
      textOfMessage: '',
      full_name: '',
      agreement: false,

      selectedFiles: null,
      items_form_selected: 1,
      items_form: [
        {
          id: 1,
          name: 'Мошенничество'
        },
        {
          id: 2,
          name: 'Благодарность/Пожелание'
        },
        {
          id: 3,
          name: 'Запрос документов'
        },
        {
          id: 4,
          name: 'Неверные данные в БКИ'
        },
        {
          id: 5,
          name: 'Решение вопроса задолженности'
        },
        {
          id: 6,
          name: 'Жалоба на сотрудника'
        },
        {
          id: 7,
          name: 'Жалоба на работу сервиса'
        },
        {
          id: 8,
          name: 'Не поступил платёж'
        },
      ],

      reviews: [
        {
          name: 'Алексей',
          shortReview: 'Круто работают ребята',
          fullReview: 'Хотел сделать сюрприз своей жене на день рождения и купить ей долгожданный подарок, но не хватило собственных сбережений. Не знал, что делать, но друг посоветовал воспользоваться услугами микрозайма через один из онлайн-сервисов. Я зарегистрировался на сайте, заполнил заявку и почти сразу получил одобрение от МФО. Процесс получения займа занял всего несколько минут, и уже через 10 минут я получил необходимую сумму на карту.'
        },
        {
          name: 'Мария',
          shortReview: 'Отличный сервис',
          fullReview: 'Оформление прошло быстро и без проблем. Деньги пришли вовремя, что меня очень порадовало. Буду рекомендовать этот сервис всем своим друзьям и знакомым. Очень довольна результатом!'
        },
        {
          name: 'Иван',
          shortReview: 'Рекомендую всем',
          fullReview: 'Очень доволен работой компании. Получил займ буквально за 10 минут. Всё было максимально просто и удобно. Отличная поддержка клиентов и выгодные условия. Буду пользоваться снова при необходимости.'
        },
        {
          name: 'Елена',
          shortReview: 'Лучший выбор',
          fullReview: 'Воспользовалась услугами и осталась очень довольна. Процесс быстрый и удобный, никаких лишних вопросов и бумажной волокиты. Деньги пришли моментально, как и обещали. Сервис на высоте!'
        },
        {
          name: 'Дмитрий',
          shortReview: 'Надёжно и быстро',
          fullReview: 'Не думал, что всё будет так просто. Заполнил заявку и сразу получил деньги на карту. Вся процедура заняла буквально несколько минут. Очень удобно, особенно когда срочно нужны деньги. Рекомендую!'
        }
      ],
    };
  },
  components: {
    Footer,
    HeaderMain,
  },
  created() {
  },
  computed: {
  },
  methods: {
    sendForm() {
      if (this.$refs.form_reg1.validate()) {

        if (this.agreement === false) {
          this.$toast.error("Пожалуйста, подтвердите согласие на обработку персональных данных.");
          return
        }

        const formData = {
          items_form_selected: this.items_form_selected,
          textOfMessage: this.textOfMessage,
          full_name: this.full_name,
          phone: this.phone,
          email: this.email,
          agreement: this.agreement,
          selectedFiles: this.selectedFiles
        };

        this.$store.dispatch("WRITE_TO_US", formData)
            .then(() => {
              this.$toast.success("Ваше сообщение отправлено успешно!");
              this.textOfMessage = "";
              this.full_name = "";
              this.phone = "";
              this.email = "";
              this.agreement = false;
              this.selectedFiles = [];
              this.agreement = false;

              this.$router.push('/');
            })
            .catch((error) => {
              this.$toast.error(error);
            });
      } else {
        this.$toast.error("Пожалуйста, заполните все поля правильно.");
      }
    },
    goToDocument(name_doc) {
      this.$router.push('/documents/' + name_doc);
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      this.selectedFiles = event.target.files;
      // Тут можно добавить логику для обработки файлов
      // console.log(this.selectedFiles);
    },
    clickOpenLabel1() {
      this.$router.push('/personal-data');
    },
    jqueryLoaded() {
    },
    removeOldScripts() {
      const scripts = document.querySelectorAll('script[src*="accordion.js"], script[src*="jquery-3.5.1.min.dc5e7f18c8.js"], script[src*="webflow.js"]');
      scripts.forEach(script => {
        script.parentNode.removeChild(script);
      });
    },
    reloadComponent() {
      this.$router.go(0);
    },
    loadScripts() {
      // Удаляем старые скрипты
      this.removeOldScripts();

      // Подключаем jQuery
      loadjs(
          "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=652921c910bae02d8870dddf",
          {
            success: () => {
              console.log('jQuery loaded');

              // Подключаем Webflow
              loadjs('./js/webflow.js', {
                success: () => {
                  console.log('Webflow loaded');
                  this.jqueryLoaded();
                },
                error: () => {
                  console.error('Failed to load Webflow');
                }
              });
            },
            error: () => {
              console.error('Failed to load jQuery');
            }
          }
      );
    },
  },
  mounted() {
    this.loadScripts();
  },

  activated() {
    this.loadScripts();
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      window.scrollTo(0, 0);
    });
  },
};
</script>

<style>
.d-none {
  display: none;
}
</style>
